import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'queryParamsToObject', standalone: true })
export class QueryParamsToObjectPipe implements PipeTransform {
	transform(params: string | null = null): { [key: string]: string } {
		return queryParamsToObject(params);
	}
}

export const queryParamsToObject = (params: string | null = null): { [key: string]: string } => {
	if (!params) return {};

	// replace &amp; with &
	params = params.replace(/&amp;/g, '&');

	const urlSearchParams = new URLSearchParams(params);
	const obj: { [key: string]: string } = {};
	urlSearchParams.forEach((value, key) => (obj[key] = value));

	return obj;
};
