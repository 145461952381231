import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replaceLineBreak', standalone: true })
export class ReplaceLineBreakPipe implements PipeTransform {
	transform(text?: string): string {
		if (!text) return '';

		// regex that checks if string has any html tags
		const regex = /(<([^>]+)>)/gi;

		// check if string has any html tags
		if (regex.test(text)) {
			return text;
		} else {
			// if string doesn't have html tags, replace all line breaks with <br> tags
			text = text.replace(/\n/g, '<br>');
			return text;
		}
	}
}
