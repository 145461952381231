import { inject, Pipe, PipeTransform } from '@angular/core';
import { NatsSiteData } from '@nats/models';
import { GLOBAL_CONFIG } from '@nats/tokens';

@Pipe({ name: 'idToPageRoute', standalone: true })
export class IdToPageRoutePipe implements PipeTransform {
	private globalConfig = inject(GLOBAL_CONFIG);

	transform(pageId: string): string {
		return idToPageRoute(this.globalConfig, pageId);
	}
}

export function idToPageRoute(globalConfig: NatsSiteData, pageId: string): string {
	const pageSlug = globalConfig.pages.find(x => x.cms_page_id === pageId)?.slug;
	return pageSlug ?? '/';
}
