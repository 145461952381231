import { inject, Pipe, PipeTransform } from '@angular/core';
import { GLOBAL_CONFIG } from '@nats/tokens';
import { replacePlaceholdersInPageSlug } from '@nats/shared/functions';

@Pipe({ name: 'replacePlaceholder', standalone: true })
export class ReplacePlaceholderPipe implements PipeTransform {
	globalConfig = inject(GLOBAL_CONFIG);

	transform(pageId: string, toBeReplaced: Record<string, string>): string {
		return replacePlaceholdersInPageSlug(this.globalConfig, pageId, toBeReplaced);
	}
}
