import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

const parser = new DOMParser();

@Pipe({ name: 'sanitizeHtml', standalone: true })
export class HtmlSanitizerPipe implements PipeTransform {
	private sanitizer = inject(DomSanitizer);

	transform(html?: string | null): SafeHtml {
		if (!html) return '';
		// html =
		// 	'<p>Hello world</p>' +
		// 	"<script>alert('Hello')</script>" +
		// 	'<div>Hello world</div>' +
		// 	'<p>Hello world</p>' +
		// 	'<span>Hello world</span>' +
		// 	'<script type="text/javascript">function a() {' +
		// 	"alert('Hello')" +
		// 	'} a(); </script>' +
		// 	'<style>div { color: black; background: red;}</style>' +
		// 	'<style>span { color: red; background: black;}</style>';

		// if html doesn't include any tags, return it as is
		if (!html.includes('<')) return html;

		const doc = parser.parseFromString(html, 'text/html');

		let safeHtml = '';

		if (doc) {
			const notAllowedTags = ['SCRIPT'];

			const allowedNodes: ChildNode[] = Array.from(doc.body.childNodes).filter(
				x => !notAllowedTags.includes(x.nodeName)
			);

			safeHtml = allowedNodes.reduce((prev, curr) => {
				if (curr.nodeName === '#text') {
					// @ts-ignore
					return prev + (curr.data || '');
				}
				// @ts-ignore
				return prev + (curr.outerHTML || '');
			}, '');

			// check if styles in head are present, if so, add them to the safeHtml
			// this solves the issue of styles not being applied to the html because if they are the first
			// child of the html body they get added in the head of the document instead of the body
			const styles = Array.from(doc.head.childNodes).filter(
				x => x instanceof HTMLStyleElement
			) as HTMLStyleElement[];
			if (styles.length) styles.forEach(style => (safeHtml += style.outerHTML));
		}

		return this.sanitizer.bypassSecurityTrustHtml(safeHtml);
	}
}
